/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
    margin-bottom: 20px;
  }
  
  .services .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: #149ddd;
    border-radius: 50%;
    transition: 0.5s;
    border: 1px solid #149ddd;
  }
  
  .services .icon i {
    color: #fff;
    font-size: 24px;
  }
  
  .services .icon-box:hover .icon {
    background: #fff;
  }
  
  .services .icon-box:hover .icon i {
    color: #149ddd;
  }
  
  .services .title {
    margin-left: 80px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .services .title a {
    color: #343a40;
  }
  
  .services .title a:hover {
    color: #149ddd;
  }
  
  .services .description {
    margin-left: 80px;
    line-height: 24px;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonial-item {
    box-sizing: content-box;
    text-align: center;
    min-height: 320px;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #c3e8fa;
    font-size: 26px;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px 15px 15px;
    padding: 20px;
    background: #fff;
    position: relative;
    margin-bottom: 35px;
    border-radius: 6px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .testimonials .testimonial-item p::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 20px solid #fff;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 20px);
  }
  
  .testimonials .owl-nav, .testimonials .owl-dots {
    margin-top: 5px;
    text-align: center;
  }
  
  .testimonials .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
  }
  
  .testimonials .owl-dot.active {
    background-color: #149ddd !important;
  }
  
  @media (max-width: 767px) {
    .testimonials {
      margin: 30px 10px;
    }
  }
